.users-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.users-container h1 {
  margin-bottom: 1.5rem;
  color: #333;
  font-size: 1.8rem;
}

.users-loading,
.users-error {
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: 1rem;
}

.users-error {
  color: #e74c3c;
}

/* Filter styles */
.users-filters {
  margin-bottom: 1.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.filter-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-end;
}

.filter-group {
  flex: 1;
  min-width: 200px;
}

.filter-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #495057;
  font-size: 0.9rem;
}

.filter-group input,
.filter-group select {
  width: 100%;
  padding: 0.6rem 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
}

.filter-group input:focus,
.filter-group select:focus {
  outline: none;
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

.filter-actions {
  display: flex;
  gap: 0.5rem;
}

.filter-button {
  padding: 0.6rem 1rem;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.filter-button:hover {
  background-color: #0d64d8;
}

.filter-button.clear {
  background-color: #f1f3f4;
  color: #5f6368;
}

.filter-button.clear:hover {
  background-color: #e8eaed;
}

/* Active filters display */
.active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
}

.active-filters span {
  font-size: 0.9rem;
  color: #666;
}

.filter-tag {
  display: inline-flex;
  align-items: center;
  background-color: #e8f0fe;
  color: #1a73e8;
  padding: 0.3rem 0.6rem;
  border-radius: 16px;
  font-size: 0.85rem;
}

.filter-tag button {
  background: none;
  border: none;
  color: #1a73e8;
  margin-left: 0.3rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 0;
  display: flex;
  align-items: center;
}

.users-results-info {
  margin-bottom: 1rem;
  color: #666;
  font-size: 0.9rem;
}

.users-table-wrapper {
  overflow-x: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.9rem;
}

.users-table th,
.users-table td {
  padding: 0.8rem 1rem;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.users-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
  position: sticky;
  top: 0;
  cursor: pointer;
  transition: background-color 0.2s;
  user-select: none;
}

.users-table th:hover {
  background-color: #e9ecef;
}

.users-table th.sorted {
  background-color: #e8f0fe;
  color: #1a73e8;
}

.sort-arrow {
  display: inline-block;
  margin-left: 0.3rem;
  font-size: 0.8rem;
}

.users-table tbody tr:hover {
  background-color: #f8f9fa;
}

.users-table tbody tr:last-child td {
  border-bottom: none;
}

.user-id {
  font-family: monospace;
  font-size: 0.85rem;
  color: #666;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Plan badge styles */
.plan-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
}

.plan-badge.starter {
  background-color: #f1f3f4;
  color: #5f6368;
  border: 1px solid #dadce0;
}

.plan-badge.yearly {
  background-color: #e3fcef;
  color: #0cad62;
  border: 1px solid #a6e9d5;
}

.plan-badge.lifetime {
  background-color: #e8f0fe;
  color: #1a73e8;
  border: 1px solid #c9d8f6;
}

.no-users {
  text-align: center;
  padding: 2rem;
  color: #888;
  font-style: italic;
}

.status-indicator {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8rem;
  font-weight: 500;
}

.status-indicator.active {
  background-color: #e3fcef;
  color: #0cad62;
}

.status-indicator.inactive {
  background-color: #feeaea;
  color: #e74c3c;
}

/* Pagination styles */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  gap: 0.8rem;
}

.pagination-button {
  padding: 0.5rem 1rem;
  background-color: #f1f3f4;
  border: 1px solid #dadce0;
  border-radius: 4px;
  color: #5f6368;
  cursor: pointer;
  font-size: 0.85rem;
  transition: all 0.2s;
}

.pagination-button:hover:not(:disabled) {
  background-color: #e8eaed;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-pages {
  display: flex;
  gap: 0.3rem;
}

.pagination-page {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #dadce0;
  background-color: #fff;
  color: #5f6368;
  cursor: pointer;
  font-size: 0.85rem;
  transition: all 0.2s;
}

.pagination-page:hover {
  background-color: #f1f3f4;
}

.pagination-page.active {
  background-color: #1a73e8;
  color: white;
  border-color: #1a73e8;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .users-container {
    padding: 1rem;
  }

  .users-table th,
  .users-table td {
    padding: 0.6rem 0.8rem;
  }

  .filter-row {
    flex-direction: column;
    gap: 1rem;
  }

  .filter-group {
    width: 100%;
  }

  .filter-actions {
    width: 100%;
    justify-content: space-between;
  }

  .pagination-pages {
    display: none;
  }

  .pagination-controls {
    justify-content: space-between;
  }
}

/* User row styles */
.user-row {
  cursor: pointer;
  transition: background-color 0.2s;
}

.user-row:hover {
  background-color: #f0f7ff !important;
}

/* Edit User Modal Styles */
.edit-user-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-user-modal {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 0;
}

.edit-user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
  background-color: #f8f9fa;
  border-radius: 8px 8px 0 0;
}

.edit-user-header h2 {
  margin: 0;
  font-size: 1.2rem;
  color: #495057;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #6c757d;
  padding: 0;
  line-height: 1;
  transition: color 0.2s;
}

.close-button:hover {
  color: #343a40;
}

.update-message {
  margin: 15px 20px;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.update-message.success {
  background-color: #e3fcef;
  color: #0cad62;
  border: 1px solid #a6e9d5;
}

.update-message.error {
  background-color: #feeaea;
  color: #e74c3c;
  border: 1px solid #f5c6cb;
}

.update-message.info {
  background-color: #e8f0fe;
  color: #1a73e8;
  border: 1px solid #c9d8f6;
}

.edit-user-form {
  padding: 0 0 20px;
}

.edit-user-table {
  width: 100%;
  border-collapse: collapse;
}

.edit-user-table th,
.edit-user-table td {
  padding: 12px 20px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.edit-user-table th {
  width: 30%;
  font-weight: 600;
  color: #495057;
  background-color: #f8f9fa;
}

.edit-user-table td input,
.edit-user-table td select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.95rem;
}

.edit-user-table td input:focus,
.edit-user-table td select:focus {
  border-color: #1a73e8;
  outline: none;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

.edit-user-actions {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 10px;
}

.cancel-button,
.save-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.cancel-button {
  background-color: #f1f3f4;
  border: 1px solid #dadce0;
  color: #5f6368;
}

.cancel-button:hover {
  background-color: #e8eaed;
}

.save-button {
  background-color: #1a73e8;
  border: 1px solid #1a73e8;
  color: white;
}

.save-button:hover {
  background-color: #1765cc;
}

.save-button:disabled {
  background-color: #a4c2f4;
  border-color: #a4c2f4;
  cursor: not-allowed;
}

/* Make sure the modal is responsive */
@media (max-width: 768px) {
  .edit-user-table th,
  .edit-user-table td {
    padding: 10px 15px;
  }

  .edit-user-table th {
    width: 40%;
  }

  .edit-user-actions {
    padding: 15px;
  }
}

/* Header with title and export button */
.users-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.users-header h1 {
  margin-bottom: 0; /* Override existing margin since we're using flexbox now */
}

.export-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.6rem 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.export-button:hover:not(:disabled) {
  background-color: #45a049;
}

.export-button:disabled {
  background-color: #a5d6a7;
  cursor: not-allowed;
}

/* Adjust existing h1 styles to account for the new header layout */
.users-container h1 {
  color: #333;
  font-size: 1.8rem;
}
