.filter-tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 20px;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
}

.filter-tag {
  padding: 8px 16px;
  border-radius: 20px;
  border: 1.5px solid #0066cc;
  color: #0066cc;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: transparent;
  white-space: nowrap;
}

.filter-tag:hover {
  background: rgba(0, 102, 204, 0.05);
  transform: translateY(-1px);
}

.filter-tag.active {
  background: #0066cc;
  color: white;
}

.filter-tag.free-tag {
  border-color: #e53e3e;
  color: #e53e3e;
}

.filter-tag.free-tag:hover {
  background: rgba(229, 62, 62, 0.05);
}

.filter-tag.free-tag.active {
  background: #e53e3e;
  color: white;
}

@media (max-width: 768px) {
  .filter-tags-container {
    gap: 8px;
    padding: 12px;
  }

  .filter-tag {
    font-size: 13px;
    padding: 6px 12px;
  }
}
