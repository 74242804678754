.add-folder-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffffff;
  color: rgb(165, 64, 232);
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 20px;
}

.add-folder-button:hover {
  background-color: #d4d5d6;
}

.purge-cache-button {
  position: fixed;
  bottom: 160px;
  right: 20px;
  background-color: #ffffff;
  color: rgb(165, 64, 232);
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 36px;
  height: 36px;
  font-size: 12px;
}
