
.add-prompt-button {
    position: fixed;
    bottom: 90px;
    right: 20px;
    background-color: #ffffff;
    color: rgb(165, 64, 232);
    border: none;
    border-radius: 50%;
    padding: 15px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 20px;
  }
  
  .add-prompt-button:hover {
    background-color: #d4d5d6;
  }
  