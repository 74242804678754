.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-container {
  background-color: #fff;
  padding: 25px;
  border-radius: 12px;
  width: 500px;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  font-family: "Inter", Arial, Helvetica, sans-serif;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.popup-content p {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
  text-align: left;
}

.credit-info-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.credit-info-card {
  position: relative;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: #f6f6f6;
  padding: 10px;
  padding-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.credit-info-balance {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #666;
  gap: 2px;
}
.credit-info-card p {
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 600;
}

.popup-search-section {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-input {
  width: 90%;
  margin: 20px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #3274ff;
  outline: none;
}

.item-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.item-list li {
  margin-bottom: 10px;
}

.item-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.item-label input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  accent-color: #3274ff;
  cursor: pointer;
}

.popup-buttons {
  display: flex;
  justify-content: right;
  margin-top: 30px;
}

.buy-credits-button,
.save-button {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.buy-credits-button {
  background-color: #ff7043;
  color: white;
}

.buy-credits-button:hover {
  background-color: #ff5722;
  transform: translateY(-2px);
}

.save-button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background: linear-gradient(90deg, #3274ff, #70a1ff);
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.save-button:hover {
  background-color: #1a5ec8;
  transform: translateY(-2px);
}

.warning-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: left;
}

.buy-credits-link {
  position: absolute;
  bottom: 8px;
  right: 8px;
  font-size: 12px;
  color: #3274ff;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.buy-credits-link:hover {
  color: #255ec8;
}
