/* src/Main.css */
.main-container {
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 852px;
  margin: 40px auto;
  padding: 20px;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.05); /* Light shadow */
}

.main-title {
  margin: 0px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  line-height: 1.334;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  text-align: center;
}

.main-notes {
  font-size: 14px;
  font-family: "Inter", Arial, sans-serif;
}

.collection-heading {
  margin-top: 40px;
}

.notes {
  text-align: left;
}
