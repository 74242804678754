.tab-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.tab {
  padding: 15px 20px;
  text-decoration: none;
  color: #555 !important;
  font-weight: bold;
  font-size: 14px;
  transition: color 0.3s ease;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  padding-bottom: 25px;
}

.tab:hover {
  color: #000;
}
.tab-navigation .tab.active {
  color: #1a73e8;

  border-bottom: 2px solid #1a73e8;
}

.credits-section {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  padding: 10px 15px;
  background: linear-gradient(90deg, #3274ff, #70a1ff);
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: 10px;

  /* hide in mobile view*/
  @media (max-width: 768px) {
    position: relative;
  }
}

.credits-section:hover {
  background: linear-gradient(90deg, #255ec8, #5f8ce0);
}

.credits-display {
  font-size: 14px;
  margin-right: 10px;
}

.credits-icon {
  height: 24px;
}
.credits-icon svg {
  width: 24px;
  height: 24px;
  fill: white; /* Set the SVG icon color to white */
}

.active-tab {
  color: #1a73e8;
  border-bottom: 2px solid #1a73e8;
}

.config-button-container {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.config-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  background: linear-gradient(90deg, #3274ff, #70a1ff);
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.config-button:hover {
  background: linear-gradient(90deg, #255ec8, #5f8ce0);
}

.config-icon {
  font-size: 18px;
}

.config-dropdown {
  position: absolute;
  right: 0;
  top: 45px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-width: 300px;
  z-index: 1000;
}

.config-section {
  position: relative;
  margin-bottom: 24px;
  right: 0;
}

.config-section:last-child {
  margin-bottom: 0;
}

.config-section h3 {
  margin: 0 0 12px 0;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.category-options {
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
}

.category-options label {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.payment-link-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
}

.payment-link-input:focus {
  outline: none;
  border-color: #3274ff;
}

.shareable-url {
  display: flex;
  gap: 8px;
}

.url-display {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: #f5f5f5;
  font-size: 13px;
  color: #666;
}

.copy-button {
  padding: 8px 16px;
  background: linear-gradient(90deg, #3274ff, #70a1ff);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
}

.copy-button:hover {
  background: linear-gradient(90deg, #255ec8, #5f8ce0);
}

.config-divider {
  height: 1px;
  background: #ddd;
  margin: 20px 0;
}

.config-item {
  width: 100%;
  padding: 10px;
  text-align: left;
  background: none;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.config-item:hover {
  background: #f5f5f5;
}

@media (max-width: 768px) {
  .config-button-container {
    position: relative;
  }

  .config-dropdown {
    position: absolute;
    width: calc(100vw - 40px);
    right: -10px;
  }
}

.nav-links {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .tab-navigation {
    justify-content: center;
  }

  .nav-links {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  .credits-section {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}
