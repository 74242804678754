.dropdown-menu-container {
  position: relative;
  display: inline-block;
  position: absolute;
  right: 0;
  z-index: 100;
  pointer-events: all;
}

.dropdown-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  background-color: white;
  border: none;
  width: 100%;
  text-align: left;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}
