/* src/SearchBar.css */
.search-bar {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.search-bar input {
  width: 80%;
  height: 50px;
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

.search-bar input::placeholder {
  color: #aaa;
}
