.prompt-popup {
  max-width: 800px;
  margin: 0 auto 20px auto;
  background: #ffffff;
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: visible;
}

@media (max-width: 600px) {
  .prompt-popup {
    max-height: 90vh;
    margin: 10px auto;
  }

  .popup-content {
    max-height: calc(90vh - 120px);
  }
}

.popup-header {
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f9fa;
  position: relative;
  padding-right: 48px;
  width: 100%;
  box-sizing: border-box;
}

.popup-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.popup-content {
  padding: 0 20px 20px 20px;
  margin: 20px 20px 0 20px;
  background: #ffffff;
  border-radius: 8px;
  overflow-y: auto;
  max-height: calc(80vh - 120px);
  padding-bottom: 40px;
}

.close-button {
  cursor: pointer;
  color: #666;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.close-button:hover {
  opacity: 0.7;
}

/* Keep all existing prompt-specific styles below this line */

.prompt-popup h2 {
  margin-top: 0;
  font-size: 20px;
  font-weight: bold;
}

.prompt-popup p {
  display: inline-block;
  font-size: 16px !important;
  color: #666;
  margin-bottom: 16px;
}

.prompt-popup .prompt-text {
  font-size: 18px;
  line-height: 1.8;
  color: #333;
  margin-top: 10px;
  text-align: center;
  margin: 0;
}

.prompt-popup .inline-input {
  border: none;
  border-bottom: 2px solid #1a73e8;
  background: none;
  outline: none;
  font-size: 16px;
  color: #333;
  padding: 4px 6px;
  margin: 10px 2px;
  width: auto;
  display: inline-block;
  text-align: center;
  transition: border-color 0.3s;
  box-sizing: content-box;
  font-family: "Inter";
}

.ql-editor {
  padding-left: 2px !important;
  padding-right: 2px !important;
  p {
    margin: 20px auto !important;
    text-align: left !important;
  }
}

h1 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.prompt-popup .inline-input:focus,
.prompt-popup .inline-input:hover {
  border-color: #0c47a1;
}

.prompt-popup .inline-input::placeholder {
  color: #1a73e8;
  text-align: center;
}

.prompt-popup button {
  background: #1a73e8;
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px !important;
  margin-top: 30px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(26, 115, 232, 0.2);
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.prompt-popup button:hover {
  background: #1558b0;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(26, 115, 232, 0.3);
}

.prompt-popup button:active {
  transform: translateY(0);
  box-shadow: 0 1px 2px rgba(26, 115, 232, 0.2);
}

.swal2-html-container {
  padding: 6px !important;
}

.hidden-placeholder {
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  font-family: inherit;
  font-size: inherit;
  padding: 1px;
}

.prompt-popup li {
  font-size: 16px !important;
  color: #666;
  margin-bottom: 16px;
}

.prompt-popup ul {
  margin-top: 10px;
  margin-bottom: 16px;
}

.prompt-popup h1 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin: 24px 0 20px;
}

.prompt-popup h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin: 22px 0 18px;
}

.prompt-popup h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin: 20px 0 16px;
}

.prompt-popup h4 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 18px 0 14px;
}

.prompt-popup h5 {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 16px 0 12px;
}

.prompt-popup h6 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 14px 0 10px;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.padding-0 {
  padding: 0px !important;
}

.popup-footer {
  padding: 16px 20px;
  border-top: 1px solid #eee;
  background: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}

.footer-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.footer-buttons .MuiButton-root {
  padding: 8px 16px;
  border-radius: 6px;
  text-transform: none;
  font-weight: 500;
  height: 36px;
  min-height: 36px;
  margin-top: 0;
  flex: 1;
  min-width: 120px;
  max-width: 200px;
}

/* Copy button */
.footer-buttons .MuiButton-root:nth-child(1) {
  background-color: #1a73e8;
}

/* ChatGPT button */
.footer-buttons .MuiButton-root:nth-child(2) {
  background-color: #19c37d;
}

/* Gemini button */
.footer-buttons .MuiButton-root:nth-child(3) {
  background-color: #1a73e8;
}

/* Claude button */
.footer-buttons .MuiButton-root:nth-child(4) {
  background-color: #6b4fbb;
}

.footer-buttons .MuiButton-root:hover {
  opacity: 0.9;
}

/* Adjust footer padding for mobile */
@media (max-width: 600px) {
  .popup-footer {
    padding: 12px;
  }

  .footer-buttons {
    gap: 6px;
  }

  .footer-buttons .MuiButton-root {
    font-size: 13px;
    padding: 8px 12px;
  }
}
