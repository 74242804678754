/*reset all prev css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

.App *,
:after,
:before {
  font-family: "Inter", Arial, sans-serif;
}

.App {
  text-align: center;
}

.App p {
  margin: 14px 0;
  line-height: 17px;
}

/* Scrollbar style */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.overflow-y-hidden {
  overflow-y: hidden;
}
