/* src/PromptList.css */
.collection-detail-container,
.collection-heading {
  display: flex;
  justify-content: center;
  align-items: start;
  margin-bottom: 20px;
  position: relative;
}

.back {
  display: flex;
  align-items: center;
  color: #1a73e8;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  width: 30px;
  height: 30px;
  position: relative;
  left: 0;
  cursor: pointer;
}

.prompt-item,
.collection-item {
  min-height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 15px;
  border-radius: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer; /* Make it clear that the item is clickable */
  transition: background-color 0.3s ease;
}

.collection-item {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.82);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.prompt-content,
.collection-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 16px;
  padding-left: 20px;
  position: relative;
}

.drag-handle {
  cursor: grab;
  padding: 0 10px;
  color: white;

  /* hide in mobile view*/
}

@media (max-width: 768px) {
  .drag-handle {
    display: none;
  }
  .name-and-path .name {
    text-align: left;
  }
}
.icon {
  font-size: 1rem;
}

.name-and-path {
  display: flex;
  flex-direction: column;
  align-items: start;
  color: white;
}

.name {
  flex-grow: 1;
  text-align: center;
}

.path {
  font-size: 12px;
  color: #fff;
}

.icon-container {
  background-color: white;
  padding: 2px;
  border-radius: 5px;
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.icon-container:hover {
  transform: scale(1.2);
}

.icon-container img {
  width: 35px;
  height: 30px;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
}

.custom-tooltip {
  background-color: rgb(18, 18, 18) !important; /* Change background color */
  color: white !important; /* Change text color */
  font-size: 13px !important; /* Example of changing font size */
  font-weight: 300 !important;
  line-height: 1.5;
  font-family: "Inter";
}

.custom-tooltip h3 {
  color: white; /* Change text color inside the title */
}

@keyframes simpleHighlight {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes wiggle {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-8px);
  }
  75% {
    transform: translateX(8px);
  }
}

.highlight-prompt {
  border: 3px solid #007aff !important; /* iOS blue color */
  animation: wiggle 0.5s ease-in-out 3; /* Run 3 times */
  z-index: 1;
}
